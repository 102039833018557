var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show"
          }
        ],
        staticClass: "lightbox"
      },
      [
        _c(
          "div",
          {
            key: "lightbox__wrapper",
            staticClass: "lightbox__wrapper clearfix"
          },
          [
            _c("div", {
              staticClass: "overlay--transparent overlay",
              on: {
                click: function($event) {
                  _vm.show = false
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lightbox__info", attrs: { tabindex: "0" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "lightbox__header",
                    attrs: { "aria-live": "assertive" }
                  },
                  [
                    _c("div", {
                      staticClass: "lightbox__title",
                      domProps: { innerHTML: _vm._s(_vm.project.title) }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "lightbox__description",
                      domProps: { innerHTML: _vm._s(_vm.project.description) }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "lightbox__nav" }, [
                  _vm.project.images.length > 1
                    ? _c(
                        "span",
                        {
                          staticClass: "visuallyhidden",
                          attrs: { "aria-live": "assertive" }
                        },
                        [
                          _vm._v(
                            "\n            Image " +
                              _vm._s(_vm.currentImageIndex + 1) +
                              " out of " +
                              _vm._s(_vm.project.images.length) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "lightbox__prev",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          _vm.projectIndex--
                        }
                      }
                    },
                    [
                      _vm._v("\n            Previous "),
                      _c("span", { staticClass: "visuallyhidden" }, [
                        _vm._v(
                          "\n              project: " +
                            _vm._s(_vm.getProjectTitle(_vm.projectIndex - 1)) +
                            "\n            "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "lightbox__next",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          _vm.projectIndex++
                        }
                      }
                    },
                    [
                      _vm._v("\n            Next "),
                      _c("span", { staticClass: "visuallyhidden" }, [
                        _vm._v(
                          "\n              project: " +
                            _vm._s(_vm.getProjectTitle(_vm.projectIndex + 1)) +
                            "\n            "
                        )
                      ])
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lightbox__slideshow" },
              [
                _c("transition", { attrs: { name: "fade" } }, [
                  _c(
                    "div",
                    {
                      key: "lightbox__images--" + _vm.projectIndex,
                      staticClass: "lightbox__images"
                    },
                    [
                      _c("transition", { attrs: { name: "fade" } }, [
                        _c("img", {
                          key:
                            "lightbox__image--" +
                            _vm.projectIndex +
                            "-" +
                            _vm.currentImageIndex,
                          staticClass: "lightbox__image",
                          attrs: {
                            width: _vm.currentImage.width,
                            height: _vm.currentImage.height,
                            src: _vm.currentImage.url,
                            alt: ""
                          }
                        })
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.project.images.length > 1
                  ? _c(
                      "div",
                      { staticClass: "lightbox__pager" },
                      _vm._l(_vm.project.images, function(item, index) {
                        return _c("a", {
                          staticClass: "lightbox__pager__link",
                          class: {
                            "is-current": _vm.currentImageIndex == index
                          },
                          attrs: {
                            href: "javascript:void(0)",
                            title: "View image " + (index + 1).toString()
                          },
                          on: {
                            click: function($event) {
                              _vm.currentImageIndex = index
                            }
                          }
                        })
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "lightbox__close close",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.show = false
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "visuallyhidden" }, [
                      _vm._v("Close")
                    ])
                  ]
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "overlay",
          on: {
            click: function($event) {
              _vm.show = false
            }
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }