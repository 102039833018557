var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "a11y" }, [
    _c(
      "div",
      {
        staticClass: "a11y__popup sidebar__popup",
        class: { "is-open": _vm.show && !_vm.showStatement },
        attrs: {
          tabindex: "9000",
          "aria-labelledby": "a11y__popup__title",
          role: "dialog"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "visuallyhidden a11y__title",
            attrs: { id: "a11y__popup__title" }
          },
          [_vm._v(_vm._s(_vm.labels[_vm.locale].accessibilityMenu))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "popup__inner" }, [
          _c(
            "a",
            {
              staticClass: "close a11y__popup__close",
              attrs: {
                tabindex: "9009",
                href: "javascript:void(0)",
                role: "button"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [
              _c("span", { staticClass: "visuallyhidden" }, [
                _vm._v(_vm._s(_vm.labels[_vm.locale].close))
              ])
            ]
          ),
          _vm._v(" "),
          _c("nav", { staticClass: "a11y__main clearfix" }, [
            _c(
              "a",
              {
                staticClass:
                  "a11y__button a11y__button--image a11y__button--font-larger",
                attrs: { tabindex: "9001", href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.fontSizeMod += 0.25
                  }
                }
              },
              [_vm._v(_vm._s(_vm.labels[_vm.locale].increaseFontSize))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "a11y__button a11y__button--image a11y__button--font-smaller",
                attrs: { tabindex: "9002", href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.fontSizeMod -= 0.25
                  }
                }
              },
              [_vm._v(_vm._s(_vm.labels[_vm.locale].decreaseFontSize))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "a11y__button a11y__button--image a11y__button--font-readable",
                class: { "is-current": _vm.readableFont },
                attrs: { tabindex: "9003", href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.readableFont = !_vm.readableFont
                  }
                }
              },
              [_vm._v(_vm._s(_vm.labels[_vm.locale].readableFont))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "a11y__button a11y__button--image a11y__button--contrast-dark",
                class: {
                  "is-current":
                    _vm.contrastMode == _vm.classes.contrastModes.dark
                },
                attrs: { tabindex: "9004", href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.contrastMode = _vm.classes.contrastModes.dark
                  }
                }
              },
              [_vm._v(_vm._s(_vm.labels[_vm.locale].contrastModeDark))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "a11y__button a11y__button--image a11y__button--contrast-light",
                class: {
                  "is-current":
                    _vm.contrastMode == _vm.classes.contrastModes.light
                },
                attrs: { tabindex: "9005", href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.contrastMode = _vm.classes.contrastModes.light
                  }
                }
              },
              [_vm._v(_vm._s(_vm.labels[_vm.locale].contrastModeLight))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "a11y__button a11y__button--image a11y__button--links",
                class: { "is-current": _vm.highlightLinks },
                attrs: { tabindex: "9006", href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.highlightLinks = !_vm.highlightLinks
                  }
                }
              },
              [_vm._v(_vm._s(_vm.labels[_vm.locale].highlightLinks))]
            )
          ]),
          _vm._v(" "),
          _c("nav", { staticClass: "a11y__secondary clearfix" }, [
            _c(
              "a",
              {
                staticClass: "a11y__button a11y__button--text a11y__off",
                attrs: { tabindex: "9007", href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.reset()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.labels[_vm.locale].turnOff))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "a11y__button a11y__button--text a11y__button--text--large a11y__off",
                attrs: { tabindex: "9008", href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.showStatement = true
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.labels[_vm.locale].accessibilityStatement) +
                    "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "overlay",
          class: { "is-open": _vm.show && !_vm.showStatement },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "a11y__statement popup popup--center a11y__statement",
        class: { "is-open": _vm.show && _vm.showStatement },
        attrs: {
          tabindex: "9100",
          role: "dialog",
          "aria-labelledby": "a11y__statement__title"
        }
      },
      [
        _c("div", { staticClass: "popup__inner" }, [
          _c(
            "a",
            {
              staticClass: "close a11y__popup__close sidebar__popup__close",
              attrs: {
                tabindex: "9200",
                role: "button",
                href: "javascript:void(0)"
              },
              on: {
                click: function($event) {
                  _vm.showStatement = false
                }
              }
            },
            [
              _c("span", { staticClass: "visuallyhidden" }, [
                _vm._v(_vm._s(_vm.labels[_vm.locale].close))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "h2",
            {
              staticClass: "a11y__statement__title",
              attrs: { id: "a11y__statement__title" }
            },
            [_vm._v(_vm._s(_vm.labels[_vm.locale].accessibilityStatement))]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "a11y__statement__content" },
            [_vm._t("default")],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "overlay",
          class: { "is-open": _vm.show && _vm.showStatement }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }