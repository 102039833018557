function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import * as utils from '../utils';
var $ = jQuery;
/**
 * The default labels, for i18n purposes
 *
 * @constant
 * @type {object}
 * @default
 */

var labels = {
  'en': {
    accessibilityMenu: 'Accessibility menu',
    increaseFontSize: 'Increase font size',
    decreaseFontSize: 'Decrease font size',
    readableFont: 'Readable font',
    contrastModeDark: 'Contrast mode dark',
    contrastModeLight: 'Contrast mode light',
    highlightLinks: 'Highlight links',
    turnOff: 'Turn off',
    close: 'Close',
    accessibilityStatement: 'Accessibility statement'
  },
  'he': {
    accessibilityMenu: 'תפריט נגישות',
    increaseFontSize: 'הגדל פונט',
    decreaseFontSize: 'הקטן פונט',
    readableFont: 'פונט קריא',
    contrastModeDark: 'מצב קונטרסט כהה',
    contrastModeLight: 'מצב קונטרסט בהיר',
    highlightLinks: 'הדגשת לינקים',
    turnOff: 'כיבוי',
    close: 'סגור',
    accessibilityStatement: 'הצהרת נגישות'
  }
};
/**
 * The body classes that are used by the component
 *
 * @constant
 * @default
 */

var classes = {
  contrastModes: {
    light: 'is-a11y-light',
    dark: 'is-a11y-dark'
  },
  highlightLinks: 'is-a11y-links',
  readableFont: 'is-a11y-readable',
  resizedFont: 'is-a11y-font'
};
/**
 * The component class.
 *
 * Note that "show" is a prop, since it is something changed by the parent,
 * while "showStatement" is a data field, since this is something that changes within
 * the component itself.
 *
 * Also, note how to close the whole popup, we $emit('close'), and put a @close event handler
 * on the actual component, to close it from the outside. As per the Vue.js official docs example.
 *
 * @class
 */

export default {
  data: function data() {
    return {
      showStatement: false,
      fontSize: 0,
      fontSizeMod: 1,
      contrastMode: '',
      highlightLinks: false,
      readableFont: false
    };
  },
  props: {
    show: _defineProperty({
      type: Boolean
    }, 'default', false),
    locale: _defineProperty({
      type: String
    }, 'default', 'en'),
    minFontSize: _defineProperty({
      type: Number
    }, 'default', 1),
    maxFontSize: _defineProperty({
      type: Number
    }, 'default', 2),
    labels: _defineProperty({
      type: Object
    }, 'default', function _default() {
      return labels;
    }),
    classes: _defineProperty({
      type: Object
    }, 'default', function _default() {
      return classes;
    })
  },
  // Watches
  watch: {
    /**
     * Focus on the main popup, when the show value changes
     *
     * @param newValue
     */
    show: function show(newValue) {
      this.focusOnPopup(newValue, '.a11y__popup');
      if (newValue == true) this.$emit('open');
      this.saveSettings();
    },

    /**
     * Focus on the statement popup, when the show value changes
     *
     * @param newValue
     */
    showStatement: function showStatement(newValue) {
      this.focusOnPopup(newValue, '.a11y__statement', '.a11y__popup');
      this.saveSettings();
    },

    /**
     * Increase/decrease font size
     *
     * @param newValue
     */
    fontSizeMod: function fontSizeMod(newValue) {
      if (newValue == 1) {
        $('html').css('font-size', '').removeClass(this.classes.resizedFont);
      } else if (newValue < this.minFontSize) {
        this.fontSizeMod = 1;
      } else if (newValue > this.maxFontSize) {
        this.fontSizeMod = this.maxFontSize;
      } else {
        $('html').css('font-size', this.fontSize * this.fontSizeMod).addClass(this.classes.resizedFont);
        setTimeout(function () {
          return $(window).trigger('resize');
        }, 250);
      }

      this.saveSettings();
    },

    /**
     * Add classes for the contrast modes
     *
     * @param newValue
     */
    contrastMode: function contrastMode(newValue) {
      $('body').removeClass(Object.values(this.classes.contrastModes).join(' ')) // Remove previous classes
      .addClass(newValue); // Add new class

      this.saveSettings();
    },

    /**
     * Toggle the highlight links class
     *
     * @param newValue
     */
    highlightLinks: function highlightLinks(newValue) {
      utils.toggleClassByVal(newValue, this.classes.highlightLinks);
    },

    /**
     * Toggle the Readable Font setting
     *
     * @param newValue
     */
    readableFont: function readableFont(newValue) {
      utils.toggleClassByVal(newValue, this.classes.readableFont);
    }
  },
  // Public methods
  methods: {
    /**
     * Reset all settings
     *
     * @method
     * @public
     */
    reset: function reset() {
      this.fontSizeMod = 1;
      this.highlightLinks = false;
      this.readableFont = false;
      this.contrastMode = '';
    }
  },

  /**
   * Initialization
   *
   * @method
   */
  mounted: function mounted() {
    this.getRootFontSize();
    this.addTabIndexToStatementLinks();
    this.loadFromCookie();
  },
  // Private helper methods
  created: function created() {
    var _this = this;

    /**
     *  Set original font size
     *
     *  @method
     *  @private
     */
    this.getRootFontSize = function () {
      _this.fontSize = parseInt($('html').css('font-size'), 10);
    };
    /**
     * Add tabindex to the links in the accessibility statement
     *
     * @method
     * @private
     */


    this.addTabIndexToStatementLinks = function () {
      var baseTabIndex = $('.a11y__statement').attr('tabindex');
      $('.a11y__statement__content a').each(function (i, item) {
        $(item).attr('tabindex', parseInt(baseTabIndex) + i + 1);
      });
    };
    /**
     * Get data from cookie, if it exists
     *
     * @method
     * @private
     */


    this.loadFromCookie = function () {
      var cookie = $.cookie('a11-settings');
      var cookieSettings = cookie ? JSON.parse(cookie) : {};

      for (var _i = 0, _Object$keys = Object.keys(cookieSettings); _i < _Object$keys.length; _i++) {
        var setting = _Object$keys[_i];
        _this[setting] = cookieSettings[setting];
      }
    };
    /**
     * Save settings to cookie
     *
     * @method
     * @private
     */


    this.saveSettings = function () {
      var settings = {
        'fontSizeMod': _this.fontSizeMod,
        'contrastMode': _this.contrastMode,
        'highlightLinks': _this.highlightLinks,
        'readableFont': _this.readableFont
      };
      $.cookie('a11-settings', JSON.stringify(settings), {
        expires: 7,
        path: '/'
      });
    };
    /**
     * Set focus on a specific popup
     *
     * @method
     * @private
     * @param newValue
     * @param onSelector
     * @param offSelector
     */


    this.focusOnPopup = function (newValue, onSelector, offSelector) {
      var $popup = $(onSelector, _this.$el);
      var $previousItem = $(offSelector, _this.$el);

      if (newValue == true) {
        setTimeout(function () {
          return $popup[0].focus();
        }, 500);
      } else if ($previousItem.length) {
        setTimeout(function () {
          return $previousItem[0].focus();
        }, 500);
      }
    };
  }
};