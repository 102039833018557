/**
 * Main Javascript file for the project. Note: this file,
 * like all of the project, uses ES6. To use it in 2017-era
 * browsers, you'll probably need something like Babel w/ Webpack
 */
import * as utils from './utils';
import * as directives from './directives';
import A11yPopup from './components/A11yPopup.vue';
import Lighbox from './components/Lightbox.vue';
var $ = jQuery;
$(function () {
  utils.markCurrentSection({
    onCurrentSection: function onCurrentSection($currentSection) {
      var subtitle = $currentSection ? $currentSection.data('subtitle') : null;
      if (subtitle) $('.sidebar__subtitle').text($currentSection.data('subtitle'));
    }
  });
  a11y();
  wrapTooltips();
  utils.scrollToLinks();
  var sidebarVm = initSidebarVue();
  utils.loadWhenIntersecting('#development', function () {
    return initDevVue();
  }, '0px 0px -50px 0px');
  utils.loadWhenIntersecting('#design', function () {
    return initDesignVue();
  });
  $('body').addClass('is-loaded');
});
/**
 * A11y adjustments
 */

function a11y() {
  // General adjustments, mostly for Gravity forms
  utils.screenReaderNotifications();
}
/**
 * Sidebar, with the a11y popup
 */


function initSidebarVue() {
  return new Vue({
    el: '#sidebar',
    data: {
      showA11Popup: false,
      isMenuOpen: false
    },
    methods: {
      closeMainMenu: function closeMainMenu() {
        $('body').removeClass('is-menu-open');
        console.log('Closing menu');
        this.isMenuOpen = false;
        setTimeout(function () {
          $('.sidebar__icon--menu').focus();
        }, 250);
      },
      toggleMainMenu: function toggleMainMenu() {
        if ($('body').hasClass('is-menu-open')) this.closeMainMenu();else this.showMainMenu();
      },
      showMainMenu: function showMainMenu() {
        $('body').addClass('is-menu-open');
        console.log('Opening menu');
        this.isMenuOpen = false;
        setTimeout(function () {
          $('.menu__item--main:first-child').focus();
        }, 250);
      }
    },
    components: {
      'a11y-popup': A11yPopup
    },
    mounted: function mounted() {
      var _this = this;

      $(window).on('keyup', function (e) {
        if (e.key === 'Escape') {
          _this.showA11Popup = false;
          if ($('body').hasClass('is-menu-open')) _this.closeMainMenu();
        }
      });
    }
  });
}
/**
 * Design projects Vue. Create an array of image arrays,
 * and pass it along to the lightbox element.
 */


function initDesignVue() {
  var projects = [];
  $('.project--design').each(function (index, item) {
    var $item = $(item);
    projects.push({
      title: $item.data('title'),
      description: $item.data('description'),
      images: $item.data('images')
    });
  });
  return new Vue({
    el: '#design',
    data: {
      projects: projects,
      currentIndex: null
    },
    components: {
      'lightbox': Lighbox
    },
    methods: {
      openLightbox: function openLightbox(index) {
        this.currentIndex = index;
        this.$refs.lightbox.goto(index);
      }
    }
  });
}

;
/**
 * Development projects Vue. Set the currently selected project
 */

function initDevVue() {
  return new Vue({
    el: '#development',
    data: {
      currentProject: null
    },
    watch: {
      currentProject: function currentProject(newVal, oldVal) {
        var _this2 = this;

        // Set focus on the original link when closing the popup
        if (newVal == 0 && oldVal != 0) {
          this.getProject(oldVal).find('.project__link').focus();
        } // And set the focus on the popup otherwise
        else {
            this.$nextTick(function () {
              _this2.getProject(newVal).find('.project__popup').focus();
            });
          }
      }
    },
    methods: {
      getProject: function getProject(index) {
        return $('.project[data-project-id="' + index + '"]', this.$el);
      },
      markOverlapping: function markOverlapping() {
        if (this.currentProject) {
          var $popup = this.getProject(this.currentProject);
          var $container = $popup.find('.project__meta');
          var $top = $popup.find('.project__popup__top');
          var $bottom = $popup.find('.project__popup__bottom');
          var isOverlapping = $top.height() + $bottom.height() >= $container.height();
          utils.toggleClassByVal(isOverlapping, 'is-overlapping', $container);
        }
      }
    },
    mounted: function mounted() {
      var _this3 = this;

      $(window).on('keyup', function (e) {
        if (e.key === 'Escape' && _this3.currentProject) {
          var $link = $("#project__link--".concat(_this3.currentProject));
          _this3.currentProject = null;
          $link.focus();
        }
      });
    }
  });
}

;
/**
 * Adjust the HTML of the tooltips, so it'll be possible to style using CSS.
 * Since the base HTML is created using the Wordpress editor, we don't have much
 * control over the final outcome.
 */

function wrapTooltips() {
  $('small.footnote').each(function (index, item) {
    var $item = $(item);
    var content = $item.html();
    var newContent = "<small class=\"footnote\">\n                           <span class=\"footnote__asterisk\">\n                            <span class=\"visuallyhidden\">*</span>\n                           </span>\n                           <span class=\"footnote__content\">\n                            ".concat(content, "\n                           </span>  \n                         </small>");
    $item.replaceWith(newContent);
  });
}