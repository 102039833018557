function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 * Vue.js plugin for directives for minor utilities
 */
import * as utils from './utils';
var $ = jQuery;

function install(Vue, options) {
  /**
   * Scroll-to links. Uses the href value of a link to turn it into a scroll-link,
   * and accepts a parameter for the speed of the scroll.
   *
   * @param speed
   */
  Vue.directive('smooth-scroll', {
    bind: function bind(el, binding) {
      var speed = binding.value || 500;
      $(el).on('click.smooth-scroll', function (e) {
        var href = $(e.currentTarget).attr('href');

        if (href) {
          e.preventDefault();
          var $target = $(href);
          utils.scrollTo($target, speed);
        }
      });
    },
    unbind: function unbind(el) {
      $(el).off('click.smooth-scroll');
    }
  });
  /**
   * Simple toggle class, with an option to select both target and class.
   * Pass either a string for the target class, or an object of { cls, target }
   *
   * @param cls
   * @param target
   */

  Vue.directive('toggle-class', {
    bind: function bind(el, binding, vnode) {
      var value = binding.value;
      var targetSelector = '';
      var targetClass = '';

      if (typeof value === 'string') {
        targetClass = value;
        targetSelector = 'body';
      } else if (_typeof(value) === 'object') {
        targetClass = value.cls;
        targetSelector = value.target;
      }

      if (targetClass && targetSelector) {
        $(el).on('click.toggle-class', function () {
          $(targetSelector).toggleClass(targetClass);
        });
      } else {
        console.error('Toggle class improperly registered', binding, vnode);
      }
    },
    unbind: function unbind(el) {
      $(el).off('click.toggle-class');
    }
  });
  /**
   * A link that only sets focus to the target element
   *
   * @param target
   * @param delay
   */

  Vue.directive('set-focus', {
    bind: function bind(el, binding) {
      var delay = '',
          target = '';

      if (_typeof(binding.value) == 'object') {
        target = binding.value.target;
        delay = binding.value.delay;
      } else {
        target = binding.value;
        delay = 250;
      }

      $(el).on('click.set-focus', function (e) {
        // Use a timeout, since sometimes Chrome messes focusing while doing other things
        setTimeout(function () {
          return $(target).focus();
        }, delay);
      });
    },
    unbind: function unbind(el) {
      $(el).off('click.set-focus');
    }
  });
  /**
   * Mark the last and penultimate items in a row, when a list represents
   * floated boxes. As in:
   * [ ] [ ] [ is-before-last ] [ is-last ]
   * [ ] [ ] [ is-before-last ] [ is-last ]
   * [ ] [ ] [ is-before-last ]
   */

  Vue.directive('mark-last', {
    bind: function bind(el, binding) {
      var params = {
        last: 'is-last',
        first: 'is-first',
        lastRow: 'is-last-row',
        beforeLast: 'is-before-last',
        items: '*'
      };

      var _$$extend = $.extend({}, params, binding.value),
          last = _$$extend.last,
          first = _$$extend.first,
          lastRow = _$$extend.lastRow,
          beforeLast = _$$extend.beforeLast,
          items = _$$extend.items;

      var $items = $(el).children(items); // The main function

      var mark = function mark() {
        // Get number of items per row
        var prevY = $items.eq(0).offset().top;
        var nextY = $items.eq(0).offset().top;
        var count = 0;
        var rows = 1;
        $items.each(function (index, item) {
          var nextY = $(item).offset().top;

          if (nextY != prevY) {
            prevY = nextY;
            rows++;
          } else {
            if (rows == 1) count++;
          }
        }); // Mark last and before items in each row

        $items.removeClass(last + ' ' + beforeLast + ' ' + lastRow + ' ' + first);

        for (var rowIndex = 0; rowIndex < rows; rowIndex++) {
          var firstInRow = $items.eq(rowIndex * count);
          var lastInRow = $items.eq(rowIndex * count + (count - 1));
          firstInRow.addClass(first);
          lastInRow.addClass(last);
          lastInRow.prev().addClass(beforeLast);
        } // Mark the last row


        for (var i = 0; i < count; i++) {
          var index = (rows - 1) * count + i;
          $items.eq(index).addClass(lastRow);
        }
      };

      $(window).on('resize.mark-last load.mark-last', mark);
    },
    unbind: function unbind() {
      $(window).off('resize.mark-last load.mark-last');
    }
  });
} // Auto-install if possible


if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install);
}

export default {
  install: install
};