function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/**
* Utility functions
*/
var $ = jQuery;
var win = window;
/**
 * Loads a library only when the interesection observable is met
 */

export function loadWhenIntersecting(selector, load) {
  var margin = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '0px 0px 200px 0px';
  var items = document.querySelectorAll(selector);

  if (items.length) {
    var observer = new IntersectionObserver(function (els) {
      els.forEach(function (entry) {
        if (entry.isIntersecting) {
          load(entry.target);
          observer.unobserve(entry.target);
        }
      });
    }, {
      rootMargin: margin
    });
    items.forEach(function (i) {
      if (i.hasAttribute('preload')) load(i);else observer.observe(i);
    });
  }
}
/**
 * Smooth scroll
 */

export function scrollTo($target) {
  var speed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  $('html, body').animate({
    scrollTop: $($target).offset().top
  }, speed);
}
/**
 * Smooth scroll-to links
 *
 * @param selector
 * @param topMargin
 * @param defaultSpeed
 */

export function scrollToLinks() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.scroll-to, [data-scroll-to]';
  var topMargin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var defaultSpeed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
  $(document).on('click', selector, function (e) {
    var $this = $(e.currentTarget);
    var href = $this.attr('href');
    var hasHash = href.indexOf('#') !== -1;

    if (href && hasHash) {
      var _href$match = href.match('(.*)#(.*)'),
          _href$match2 = _slicedToArray(_href$match, 3),
          _ = _href$match2[0],
          hashBeginning = _href$match2[1],
          hashTarget = _href$match2[2];

      if (hashBeginning !== win.location.href && hashBeginning) return true;
      e.preventDefault();
      var $target = $('#' + hashTarget);
      var speed = parseInt($(e.currentTarget).attr('data-scroll-to'), 10) || defaultSpeed;
      var margin = $this.is('[data-scroll-to-margin]') ? $this.attr('data-scroll-to-margin') : topMargin;
      scrollTo($target[0], speed, margin);
    }
  });
}
/**
 * Toggle class, depending on whether the "val" parameter is true or not
 *
 * @param val
 * @param cls
 * @param [targetSelector]
 */

export function toggleClassByVal(val, cls) {
  var targetSelector = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'body';
  var $target = $(targetSelector);
  if (val) $target.addClass(cls);else $target.removeClass(cls);
}
/**
 * Get the mobile size from the stylesheet
 *
 * @returns {boolean}
 */

export function isMobile() {
  var defaultSize = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 750;
  var link = $('link[rel="stylesheet"][media*="max-width"][href*="mobile.css"]');
  var size = link.length ? parseInt(link.attr('media').match(/(\d+)/)[0]) : defaultSize;
  return $(window).width() <= size;
}
/**
 * Automatic full-height elements
 *
 * @param selector
 * @param useFullHeight
 */

export function fullHeight() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.full-height';
  var useFullHeight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.full-height--full';
  var $el = $(selector);

  var resize = function resize() {
    return $(selector).each(function (index, item) {
      var property = $(item).is(useFullHeight) ? 'height' : 'min-height';
      $(item).css(property, $(window).height());
    });
  };

  $(window).on('load resize', function () {
    return resize();
  });
  resize();
}
/**
 * Get the current section, by getting the element in the middle of the screen
 *
 * @param selector
 * @returns {*}
 */

export function getCurrentSectionFromPoint() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.section';
  var centerElement = document.elementFromPoint($(window).width() / 2, $(window).height() / 2);
  var $centerElement = $(centerElement);
  if ($centerElement.is(selector)) return $centerElement;else {
    var parent = $centerElement.parents().filter(selector);
    if (parent.length) return parent;else return null;
  }
}
/**
 * Wrap-around value, when it doesn't fall within (min, max]
 *
 * @param val
 * @param min
 * @param max
 * @return Number
 */

export function wraparound(val, min, max) {
  if (val < min) return max - 1;else if (val >= max) return min;else return val;
}
/**
 * Find if two elements are overlapping
 */

export function isOverlapping($el1, $el2) {
  var getPos = function getPos($el) {
    return {
      left: $el.offset().left,
      top: $el.offset().top,
      bottom: $el.offset().top + $el.outerHeight(true),
      right: $el.offset().left + $el.outerWidth(true),
      height: $el.outerHeight(true),
      width: $el.outerWidth(true)
    };
  };

  var el1 = getPos($el1);
  var el2 = getPos($el2);
  return el1.bottom >= el2.top && el1.top <= el2.bottom && el2.right >= el1.left && el1.left <= el2.right;
}
/**
 * Get the current section from calculating the exact position relative to the scrollPosition
 *
 * @param selector
 * @returns {*}
 */

export function getCurrentSectionFromSectionPos() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.section';
  var centerMargin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var centerPoint = $(window).scrollTop() + $(window).height() / 2 + centerMargin;
  var $sections = $(selector);
  var currentItem = null;
  $sections.each(function () {
    var $section = $(this);
    var top = $section.offset().top;
    var bottom = top + $section.outerHeight(true);

    if (centerPoint > top && bottom > centerPoint) {
      currentItem = $section;
    }
  });
  return currentItem;
}
/**
 * Play/pause videos on click
 */

export function toggleVideoPlay() {
  $(document).on('click', 'video', function () {
    var video = $(this)[0];
    if (video.paused && video.readyState == 4) video.play();else video.pause();
  });
}
/**
 * Mark the header when it's scrolling down, so we can give
 * it another appearance, make it position: fixed and so on
 *
 * @param margin
 * @param headerSelector
 * @param downClass
 */

export function markScrolledDown() {
  var margin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 50;
  var headerSelector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.header';
  var downClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'is-down';
  var $header = $(headerSelector);

  var mark = function mark() {
    if ($(window).scrollTop() > margin) {
      $header.addClass(downClass);
    } else {
      $header.removeClass(downClass);
    }
  };

  $(window).on('scroll load', mark);
  mark();
}
/**
 * Remove the hash from the location, without triggering a refresh
 */

export function removeHash() {
  history.pushState("", document.title, window.location.pathname + window.location.search);
}
/**
 * Marks the current section
 *
 * @param options
 */

export function markCurrentSection(options) {
  var defaults = {
    onCurrentSection: function onCurrentSection(section) {},
    getCurrentSection: getCurrentSectionFromSectionPos,
    refreshRate: 150,
    selector: '.section',
    linksSelector: 'a.menu__item',
    currentClass: 'is-current-section',
    sectionAttr: 'data-section',
    bodyAttr: 'data-current-section'
  };

  var _$$extend = $.extend({}, defaults, options),
      onCurrentSection = _$$extend.onCurrentSection,
      getCurrentSection = _$$extend.getCurrentSection,
      refreshRate = _$$extend.refreshRate,
      selector = _$$extend.selector,
      linksSelector = _$$extend.linksSelector,
      currentClass = _$$extend.currentClass,
      sectionAttr = _$$extend.sectionAttr,
      bodyAttr = _$$extend.bodyAttr;

  var $sections = $(selector);
  var $links = $(linksSelector); // Throttle the onScroll, for performance

  var isScrolling = false;
  var $previousSection = $('');

  var onScroll = function onScroll() {
    isScrolling = true;
  };

  var doScroll = function doScroll() {
    if (isScrolling) {
      var $currentSection = getCurrentSection(selector); // Callback when change

      var currentId = $currentSection ? $currentSection.attr('id') : NaN; // Use NaN because NaN != NaN

      var previousId = $previousSection ? $previousSection.attr('id') : NaN;

      if (currentId != previousId) {
        onCurrentSection($currentSection);
        $previousSection = $currentSection;
      }

      if ($currentSection && $currentSection.length) {
        var $currentLink = $(linksSelector).filter('[href="#' + currentId + '"]');
        $(linksSelector).removeClass(currentClass);
        $currentLink.addClass(currentClass);
        $sections.removeClass(currentClass);
        $currentSection.addClass(currentClass);
        $('body').attr(bodyAttr, $currentSection.attr(sectionAttr));
      }

      isScrolling = false;
    }
  };

  setInterval(doScroll, refreshRate);
  doScroll();
  $(window).on('scroll load resize', onScroll);
}
/**
 * Add a class to empty form elements, for styling
 *
 * @param selector
 */

export function markEmptyInputs() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'input, select, textarea';

  var mark = function mark() {
    $(selector).each(function () {
      if (!$(this).val()) $(this).addClass('is-empty');else $(this).removeClass('is-empty');
    });
  };

  mark();
  $(document).on('change keyup', selector, mark);
}
/**
 * Mark the input's container with an is-focused class when it's in focus,
 * so we can properly style the focused mode. Especially useful for Ninja Forms 3
 *
 * @param selector
 * @param parent
 */

export function markFocusedInputContainer() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.submit-wrap input[type="button"]';
  var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.submit-wrap';
  $(document).on('focus', selector, function (e) {
    $(e.currentTarget).parents(parent).addClass('is-focused');
  });
  $(document).on('blur', selector, function (e) {
    $(e.currentTarget).parents(parent).removeClass('is-focused');
  });
}
/**
 * Mark items that are in the viewport. Relies on the jquery.viewport plugin
 *
 * @param itemSelector
 */

export function markItemsInViewport(itemSelector) {
  $(itemSelector).each(function (i, item) {
    if ($(item).is(':in-viewport')) $(item).addClass('in-viewport');else $(item).removeClass('in-viewport');
  });
}
/**
 * Add a "screen-reader-notify" event to the window,
 * that allows us to voice messages, for a11y purposes
 */

export function screenReaderNotifications() {
  var $notification = $('.screen-reader-notification');

  if (!$notification.length) {
    $notification = $("<div class=\"screen-reader-notification visuallyhidden\" aria-live=\"assertive\"></div>").prependTo('body');
  }

  var notify = function notify(message) {
    $notification.attr('role', 'alert').html(message);
  };

  $(window).on('screen-reader-notify', function (e, message) {
    notify(message);
  }); // Notify on GForms errors, since Firefox doesn't seem to register them

  var onGFormErrors = function onGFormErrors() {
    if ($('#error').length) notify($('#error').text());
    if ($('.gform_confirmation_message').length) notify($('.gform_confirmation_message').text());
  }; // Notify on gform loading


  var onGFormLoading = function onGFormLoading() {
    notify('Submitting form, please wait');
    console.log('Loading...');
  }; // GForms errors send focus to the li rather than the form input. Change that behavior


  $(document).on('click', '#error .validation_list a', function (e) {
    var targetMatches = $(e.currentTarget).attr('href').match(/#(.*)/);
    var target = targetMatches ? targetMatches[1] : null;

    if (target) {
      e.preventDefault();
      var item = $('#' + target).find('input,select,textarea')[0];
      item.focus();
    }
  });
  $(document).on('gform_post_render', function () {
    return onGFormErrors();
  });
  $(document).on('submit', '.gform_wrapper form', function (form) {
    return onGFormLoading();
  });
  $(window).on('load', function () {
    return onGFormErrors();
  });
}