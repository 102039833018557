function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import * as utils from '../utils';
var $ = jQuery;
/**
 * The component class. Gets a list of projects and an initial index as a prop
 */

export default {
  data: function data() {
    return {
      projectIndex: 0,
      currentImageIndex: 0,
      show: false
    };
  },
  computed: {
    project: function project() {
      return this.projects[this.projectIndex];
    },
    currentImage: function currentImage() {
      return this.project.images[this.currentImageIndex];
    }
  },
  props: {
    // Array of objects with the following structure:
    // { title, subtitle, [{ url, width, height }]}
    projects: _defineProperty({
      type: Array
    }, 'default', []),
    maxWidthRatio: _defineProperty({
      type: Number
    }, 'default', 0.9),
    maxHeightRatio: _defineProperty({
      type: Number
    }, 'default', 0.9),
    margin: _defineProperty({
      type: Number
    }, 'default', 40)
  },
  watch: {
    // Wraparound images and projects
    projectIndex: function projectIndex(newVal, oldVal) {
      this.projectIndex = utils.wraparound(newVal, 0, this.projects.length);
      if (newVal != oldVal) this.currentImageIndex = 0;
      this.resizeImageBox();
    },
    currentImageIndex: function currentImageIndex(val) {
      this.currentImageIndex = utils.wraparound(val, 0, this.project.images.length);
      this.resizeImageBox();
    },
    // Focus main element on popup
    show: function show(val) {
      var _this = this;

      this.resizeImageBox();

      if (val == true) {
        this.$emit('show');
        setTimeout(function () {
          var $lightbox = $('.lightbox__info', _this.$el);
          $lightbox[0].focus();
        }, 500);
      } else {
        this.$emit('hide');
        var $link = $("#project__open--design--".concat(this.projectIndex));
        console.log($link, "project__open--design--".concat(this.projectIndex));
        this.$nextTick(function () {
          return $link.focus();
        });
      }
    }
  },
  methods: {
    /**
     * Goes to a specific slide
     *
     * @param index
     * @method
     * @public
     */
    goto: function goto(index) {
      this.projectIndex = index;
      this.show = true;
      this.$emit('goto', index);
    },

    /**
     * Get the next/prev project's title, for a11y purposes
     */
    getProjectTitle: function getProjectTitle(index) {
      var adjustedIndex = utils.wraparound(index, 0, this.projects.length);
      return this.projects[adjustedIndex] ? this.projects[adjustedIndex].title : '';
    },

    /**
     * Calculate the correct height/width for the image box,
     * based on its proportions and the screen size
     */
    resizeImageBox: function resizeImageBox() {
      var $window = $(window);
      var $lightbox = $(this.$el);
      var $wrapper = $('.lightbox__wrapper', $lightbox);
      var $info = $('.lightbox__info', $lightbox);
      var $slideshow = $('.lightbox__slideshow', $lightbox);
      var margin = utils.isMobile() ? 0 : this.margin;
      var maxWidthRatio = utils.isMobile() ? 1 : this.maxWidthRatio;
      var maxHeightRatio = utils.isMobile() ? 1 : this.maxHeightRatio;
      var maxWidth = ($window.width() - margin * 2) * maxWidthRatio;
      var maxHeight = ($window.height() - margin * 2) * maxHeightRatio;
      var ratio = this.currentImage.height / this.currentImage.width; // If there's no margin, then the info box is above

      var infoMargin = parseInt($info.css('margin-right'));
      var isDesktop = infoMargin > 0;
      var infoWidth = isDesktop ? $info.outerWidth(true) : 0;
      var infoHeight = $info.height() ? $info.height() : parseInt($info.css('min-height'));
      var targetWidth = maxWidth - infoWidth;
      var targetHeight = targetWidth * ratio;

      var overallHeight = function overallHeight() {
        return isDesktop ? Math.max(infoHeight, targetHeight) : infoHeight + targetHeight;
      };

      var overallWidth = function overallWidth() {
        return targetWidth + infoWidth;
      };

      if (overallHeight() >= maxHeight) {
        targetHeight = isDesktop ? maxHeight : maxHeight - infoHeight;
        targetWidth = targetHeight / ratio;
      }

      if (targetWidth && targetHeight) {
        $slideshow.width(targetWidth);
        $slideshow.height(targetHeight);
        $lightbox.css('padding', margin);
        $wrapper.css({
          'width': overallWidth(),
          'max-height': overallHeight(),
          'margin-top': -overallHeight() / 2
        });
      }
    }
  },
  // Add custom events
  mounted: function mounted() {
    var _this2 = this;

    // Keyboard navigation
    $(window).on('keyup', function (e) {
      if (_this2.show) {
        if (e.key === 'Left') // left
          _this2.currentImageIndex--;else if (e.key === 'Right') // right
          _this2.currentImageIndex++;else if (e.key === 'Escape') // escape
          _this2.show = false;
      }
    }); // Resize the lightbox

    $(window).on('resize load', function (e) {
      _this2.resizeImageBox();
    });
    this.resizeImageBox();
  }
};